import React, { Component } from "react";
import "./index.css";

import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import AddCircle from "@material-ui/icons/AddCircleRounded";
import Cross from "@material-ui/icons/CancelRounded";
import TextField from "@material-ui/core/TextField";

import pageConstants from "../../consts/pageConstants.json";
import dictionary from "../../consts/MainDictionary.json";

export default class Filter extends Component {
  constructor() {
    super();
    this.state = {
      selectedWord: null,
      expandMainCategory: true,
      searchEnabled: false,
    };
    this.typedText = "";
    this.errorText = "";
  }

  renderChip = (chipType, object, isAllLabel = false) => {
    if (object.is_visible) {
      const selected = chipType === chipTypes.SELECTED;
      const sameCategory =
        this.state.selectedWord?.category === object.category;
      const func = selected
        ? this.onPressChipRemoveButton
        : this.onPressChipSelectButton;
      const labelStyles = isAllLabel
        ? "selectable-chip-text-roboto"
        : selected || sameCategory
        ? "selectable-chip-text-selected"
        : "selectable-chip-text";
      const color =
        (selected || sameCategory) && !isAllLabel ? "primary" : "default";
      const clickable = `selectable-chip ${
        selected || sameCategory ? "" : "not-selected"
      }${isAllLabel ? "all-label" : ""}`;

      const icon = selected ? (
        <Cross style={{ fill: isAllLabel ? "#a93e3c50" : null }} />
      ) : (
        <AddCircle style={{ fill: sameCategory ? null : "#a93e3c50" }} />
      );
      return (
        <Chip
          key={object.category}
          label={object.category}
          clickable={true}
          onDelete={() => func(object)}
          onClick={() => func(object)}
          color={color}
          disabled={isAllLabel || (!selected && sameCategory)}
          classes={{
            clickable: clickable,
            label: labelStyles,
          }}
          deleteIcon={icon}
        />
      );
    }
    return null;
  };

  renderSelectedChip = () => {
    const AllObject = {
      category: "සියල්ල",
      is_visible: true,
    };
    return this.state.selectedWord === null
      ? this.renderChip(chipTypes.SELECTED, AllObject, true)
      : this.renderChip(chipTypes.SELECTED, this.state.selectedWord);
  };

  onPressChipRemoveButton = () => {
    window.scrollTo(0, 0);
    this.setState({ selectedWord: null, expandMainCategory: true });
    this.props.onSetNewCategory(null);
  };

  onPressChipSelectButton = (obj) => {
    window.scrollTo(0, 0);
    this.setState({ selectedWord: obj });
    this.onClickIconButton();
    this.props.onSetNewCategory(obj);
  };

  onClickIconButton = () => {
    if (!this.state.searchEnabled)
      this.setState({ expandMainCategory: !this.state.expandMainCategory });
    else {
      this.setState({ searchEnabled: false, expandMainCategory: true });
      this.onPressChipRemoveButton();
      this.props.onSearchStatusChange(false);
      this.typedText = "";
      this.errorText = "";
    }
  };

  onClickSearch = () => {
    this.props.onSearchStatusChange(true);
    this.setState({ expandMainCategory: false, searchEnabled: true });
  };

  onChangeText = (event) => {
    if (event.target.value.match(/[^a-zA-Z]/)) {
      this.errorText = pageConstants.error;
      this.forceUpdate();
    } else {
      const newText = event.target.value.replace(/[^a-zA-Z]/, "");
      if (newText !== this.typedText) {
        this.errorText = "";
        this.typedText = event.target.value;
        this.props.onChangeText(event.target.value);
        this.forceUpdate();
      }
    }
  };

  accordionSummaryShifter = () => {
    const { searchEnabled } = this.state;
    if (!searchEnabled) {
      return (
        <AccordionSummary
          classes={{ root: "accordian-summary" }}
          IconButtonProps={{ onClick: this.onClickIconButton }}
          expandIcon={
            <IconButton disableRipple={true} disableFocusRipple>
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <Typography className="accordian-title">
            {pageConstants.filterTitle}
          </Typography>
          {this.renderSelectedChip()}
          <div className="search-button">
            <IconButton
              onClick={this.onClickSearch}
              disableRipple={true}
              disableFocusRipple
            >
              {searchEnabled ? null : <SearchOutlinedIcon />}
            </IconButton>
          </div>
        </AccordionSummary>
      );
    }
    return (
      <AccordionSummary
        classes={{ root: "accordian-summary" }}
        IconButtonProps={{ onClick: this.onClickIconButton }}
        expandIcon={
          <IconButton disableRipple={true} disableFocusRipple>
            <CloseOutlinedIcon />
          </IconButton>
        }
      >
        <TextField
          id="outlined-basic"
          label={pageConstants.search}
          variant="outlined"
          onChange={this.onChangeText}
          classes={{ root: "search-input" }}
          value={this.typedText}
          error={this.errorText.length !== 0}
          helperText={this.errorText}
        />
      </AccordionSummary>
    );
  };

  render() {
    return (
      <Accordion
        expanded={this.state.expandMainCategory}
        defaultExpanded={true}
        classes={{ root: "accordian-root" }}
      >
        {this.accordionSummaryShifter()}
        <AccordionDetails classes={{ root: "accordian-details" }}>
          {Array(dictionary.length)
            .fill()
            .map((_, i) => i)
            .map((i) => {
              return this.renderChip(chipTypes.NONSELECTED, dictionary[i]);
            })}
        </AccordionDetails>
      </Accordion>
    );
  }
}

const chipTypes = {
  SELECTED: "selected",
  NONSELECTED: "non-selected",
};
