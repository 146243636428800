import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

import Header from "../../component/header";
import TopNavBar from "../../component/topNav";
import PageConstants from "../../consts/pageConstants.json";
import metaConsts from "../../consts/meta.json";
import "./index.css";
import Footer from "../../views/footer";

class Disclaimer extends Component {
  render() {
    return (
      <div className="disclaimer container-bg">
        <MetaTags>
          <title>{metaConsts.disclaimer.title}</title>
          <meta name="description" content={metaConsts.disclaimer.desc} />
          <meta property="og:title" content={metaConsts.disclaimer.title} />
        </MetaTags>
        <TopNavBar
          currentPath={this.props.currentPath}
          history={this.props.history}
        />
        <Header
          name={PageConstants.appname}
          sinhaleappname={PageConstants.sinhaleappname}
        />
        <div className="disclaimer-content">
          <h1>{PageConstants.disclaimer.title}</h1>
          <div className="disclaimer-text-container">
            <span className="disclaimer-body-text">
              {PageConstants.disclaimer.body}
            </span>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Disclaimer);
