import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

import "./index.css";
import Header from "../../component/header";
import MainFilter from "../../component/main_filter";
import SubFilter from "../../component/sub_filter";
import BodyContainer from "../../views/bodyContainer";
import PageConstants from "../../consts/pageConstants.json";
import metaConsts from "../../consts/meta.json";
import Footer from "../../views/footer";
import TopNavBar from "../../component/topNav";
import topImage from "../../assets/Images/top-img.png";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      selectedCategory: {},
      selectedSubCategory: {},
      isSearchEnable: false,
      searchText: "",
    };
    this.timeout = null;
  }

  onSetNewCategory = (object) => {
    // Object can be null too.
    this.setState({ selectedCategory: object ? object : {} });
  };

  onSetNewSubCategory = (object) => {
    // Object can be null too.
    this.setState({ selectedSubCategory: object ? object : {} });
  };

  onChangeTextOnSearch = (text) => {
    if (this.state.isSearchEnable) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ searchText: text });
      }, 1000);
    }
  };

  onSearchStatusChange = (status) => {
    // This gets fired if the Search is enabled of disabled.
    // Either way it changes searcText to empty.
    this.setState({ isSearchEnable: status, searchText: "" });
  };

  render() {
    const {
      selectedCategory,
      selectedSubCategory,
      searchText,
      isSearchEnable,
    } = this.state;
    const subFilter =
      this.state.selectedCategory?.sub_categories?.length > 0 ? (
        <SubFilter
          selectedMainCategory={selectedCategory}
          onSetNewSubCategory={this.onSetNewSubCategory}
        />
      ) : null;
    return (
      <div className="container container-bg">
        <MetaTags>
          <title>{metaConsts.home.title}</title>
          <meta name="description" content={metaConsts.home.desc} />
          <meta property="og:title" content={metaConsts.home.title} />
        </MetaTags>
        <TopNavBar
          currentPath={this.props.currentPath}
          history={this.props.history}
        />
        <Header
          name={PageConstants.appname}
          sinhaleappname={PageConstants.sinhaleappname}
        />
        <img class="top-img" alt="Design" src={topImage} />
        <div className="content">
          <MainFilter
            onChangeText={this.onChangeTextOnSearch}
            onSetNewCategory={this.onSetNewCategory}
            onSearchStatusChange={this.onSearchStatusChange}
          />
          {subFilter}
          <BodyContainer
            category={selectedCategory}
            subCategory={selectedSubCategory}
            searchText={searchText}
            isSearchEnable={isSearchEnable}
            history={this.props.history}
            currentPath={this.props.currentPath}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Home);
