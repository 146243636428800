import React, { Component } from "react";
import FlareIcon from "@material-ui/icons/Flare";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

import Header from "../../component/header";
import TopNavBar from "../../component/topNav";
import PageConstants from "../../consts/pageConstants.json";
import metaConsts from "../../consts/meta.json";
import "./index.css";
import Footer from "../../views/footer";

class Editorial extends Component {
  render() {
    return (
      <div className="editorial container-bg">
        <MetaTags>
          <title>{metaConsts.editorial.title}</title>
          <meta name="description" content={metaConsts.editorial.desc} />
          <meta property="og:title" content={metaConsts.editorial.title} />
        </MetaTags>
        <TopNavBar
          currentPath={this.props.currentPath}
          history={this.props.history}
        />
        <Header
          name={PageConstants.appname}
          sinhaleappname={PageConstants.sinhaleappname}
        />
        <div className="editorial-content">
          <h1>{PageConstants.editorial.titleOne}</h1>
          <div className="editorial-board">
            {Array(PageConstants.editorial.boardOne.length)
              .fill()
              .map((_, i) => i)
              .map((i) => (
                <span
                  key={PageConstants.editorial.boardOne[i]}
                  className="board-name"
                >
                  <FlareIcon classes={{ root: "editorial-icon" }} />
                  {PageConstants.editorial.boardOne[i]}
                </span>
              ))}
          </div>
          <h1>{PageConstants.editorial.titleTwo}</h1>
          <div className="editorial-board">
            {Array(PageConstants.editorial.boardTwo.length)
              .fill()
              .map((_, i) => i)
              .map((i) => (
                <span
                  key={PageConstants.editorial.boardTwo[i]}
                  className="board-name"
                >
                  <FlareIcon classes={{ root: "editorial-icon" }} />
                  {PageConstants.editorial.boardTwo[i]}
                </span>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Editorial);
