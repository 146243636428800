import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

import Header from "../../component/header";
import TopNavBar from "../../component/topNav";
import PageConstants from "../../consts/pageConstants.json";
import PrefaceConsts from "../../consts/preface.json";
import metaConsts from "../../consts/meta.json";
import "./index.css";
import Footer from "../../views/footer";

class Preface extends Component {
  render() {
    return (
      <div className="preface container-bg">
        <MetaTags>
          <title>{metaConsts.preface.title}</title>
          <meta name="description" content={metaConsts.preface.desc} />
          <meta property="og:title" content={metaConsts.preface.title} />
        </MetaTags>
        <TopNavBar
          currentPath={this.props.currentPath}
          history={this.props.history}
        />
        <Header
          name={PageConstants.appname}
          sinhaleappname={PageConstants.sinhaleappname}
        />
        <div className="preface-content">
          <h1>{PrefaceConsts.title}</h1>
          <div className="preface-text-container">
            <span className="preface-body-text">
              {PrefaceConsts.body.body1}
            </span>
            <span className="preface-body-text">
              {PrefaceConsts.body.body2}
            </span>
            <span className="preface-body-text">
              {PrefaceConsts.body.body3}
            </span>
            <span className="preface-body-text">
              {PrefaceConsts.body.body4}
            </span>
          </div>
          <div className="writers-content">
            <div className="writer-single-content">
              <span className="writer-bold">{PrefaceConsts.writers.writer1.name}</span>
              <span className="writer-semibold">{PrefaceConsts.writers.writer1.position}</span>
            </div>
            <div className="writer-single-content">
              <span className="writer-bold">{PrefaceConsts.writers.writer2.name}</span>
              <span className="writer-semibold">{PrefaceConsts.writers.writer2.position}</span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Preface);
