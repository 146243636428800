import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Disclaimer from "./screens/disclaimer";
import Editorial from "./screens/editorial";
import Home from "./screens/home";
import Meaning from "./screens/meaning";
import NotFoundScreen from "./screens/notfound";
import Preface from "./screens/preface";
import { removeDomain } from "./utils";

export default class App extends Component {
  constructor() {
    super();
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      this.Path = removeDomain("http://localhost:3000");
    } else {
      this.Path = process.env.PUBLIC_URL;
    }
  }

  render() {
    return (
      <Router>
        <div style={{ width: "100%" }}>
          <Switch>
            <Route exact path={`${this.Path}/`}>
              <Home currentPath={`${this.Path}`} />
            </Route>
            <Route exact path={`${this.Path}/editorial`}>
              <Editorial currentPath={`${this.Path}`} />
            </Route>
            <Route exact path={`${this.Path}/preface`}>
              <Preface currentPath={`${this.Path}`} />
            </Route>
            <Route exact path={`${this.Path}/disclaimer`}>
              <Disclaimer currentPath={`${this.Path}`} />
            </Route>
            <Route exact path={`${this.Path}/notfound`}>
              <NotFoundScreen currentPath={`${this.Path}`} />
            </Route>
            <Route
              exact
              path={`${this.Path}/meaning/:ID([0-9]{2})/:subID([0-9]{2})/:itmID([0-9]{2})`}
            >
              <Meaning currentPath={`${this.Path}`} />
            </Route>
            <Route
              render={(props) => (
                <NotFoundScreen {...props} currentPath={`${this.Path}`} />
              )}
            ></Route>
          </Switch>
        </div>
      </Router>
    );
  }
}
