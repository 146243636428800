import React, { Component } from "react";
import "./index.css";
import UniLogo from "../../assets/Images/usj-logo.png";
export default class Header extends Component {
  onClickUniLogo = () => {
    window.open("http://fhss.sjp.ac.lk");
  };

  render() {
    return (
      <div className="header-bar">
        <img
          onClick={this.onClickUniLogo}
          className="header-uni-logo"
          alt="University Logo"
          src={UniLogo}
        />
        <div className="header-name-bar">
          <h1 className="header-sinhala-name">{this.props.sinhaleappname}</h1>
          <h1 className="header-name">{this.props.name}</h1>
          <h3 className="header-name">
            International Center for Multidisciplinary Studies
          </h3>
          <h3 className="header-name-uni">
            Faculty of Humanities and Social Sciences, University of Sri
            Jayewardenepura
          </h3>
        </div>
      </div>
    );
  }
}
