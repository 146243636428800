import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

import Header from "../../component/header";
import TopNavBar from "../../component/topNav";
import PageConstants from "../../consts/pageConstants.json";
import metaConsts from "../../consts/meta.json";
import "./index.css";
import Footer from "../../views/footer";
import meaningsFile from "../../consts/meanings.json";
import { capitalize, isArray } from "../../utils";

class WordMeaningPage extends Component {
  constructor(props) {
    super(props);
    const prps = props.match.params;
    this.content = meaningsFile[`${prps.ID}_${prps.subID}_${prps.itmID}`];
    if (!this.content)
      this.props.history.replace(`${this.props.currentPath}/notfound`);
    this.state = {
      content: [],
      sinhalaTitle: "",
      englishTitle: "",
      author: "",
      refs: "",
    };
  }

  componentDidMount() {
    if (this.content) {
      this.generateBody();
      this.getTitles();
      this.getAuthorAndReferences();
      window.scrollTo(0, 0);
    }
  }

  getTitles = () => {
    const eng = this.content?.title
      ?.match(/\[(.*?)\]/g)[0]
      .replace(/[^a-zA-Z\s]/g, "");
    const sin = this.content?.title?.split("[")[0];
    this.setState({ sinhalaTitle: sin, englishTitle: eng });
  };

  getAuthorAndReferences = () => {
    if (this.content.writer) {
      this.setState({ author: this.content.writer });
    }
    if (
      this.content.additonal_resources &&
      this.content.additonal_resources.length > 0
    ) {
      const resources = [];
      this.content.additonal_resources.forEach((res) => {
        resources.push(<p className="meaning-references-item">{res}</p>);
      });
      this.setState({ refs: resources });
    }
  };

  generateBody = () => {
    const items = [];
    if (isArray(this.content.body)) {
      this.content.body = this.content.body.join("");
    }
    const bodyComponents = this.content.body.split(/({img:[0-9]{1,}})/);
    for (let i = 0; i < bodyComponents.length; i++) {
      if (
        bodyComponents[i].includes("{img:") &&
        this.content?.images?.length > 0
      ) {
        const imgID = bodyComponents[i].split(":")[1].replace(/[^0-9]/, "");
        items.push(
          <img
            className="meanings-figure-image"
            src={this.content.images.find((obj) => obj.id === imgID).url}
            alt={`Figure ${imgID}`}
          />
        );
      } else {
        items.push(
          <span className="meaning-content-para-text">{bodyComponents[i]}</span>
        );
      }
    }
    this.setState({ content: items });
  };

  render() {
    return (
      <div className="meaning container-bg">
        <MetaTags>
          <title>{metaConsts.meaning.title}</title>
          <meta name="description" content={metaConsts.meaning.desc} />
          <meta property="og:title" content={metaConsts.meaning.title} />
        </MetaTags>
        <TopNavBar
          currentPath={this.props.currentPath}
          history={this.props.history}
        />
        <Header
          name={PageConstants.appname}
          sinhaleappname={PageConstants.sinhaleappname}
        />
        <div className="meaning-content">
          <h1 className="meaning-sinhala-heading">{this.state.sinhalaTitle}</h1>
          <h3 className="meaning-english-heading">
            {capitalize(this.state.englishTitle)}
          </h3>
          {this.state.content}
          <span className="meaning-author">{this.state.author}</span>
          <div className="meaning-full-length">
            <div className="meaning-resources">
              <h3>{PageConstants.references}</h3>
              {this.state.refs}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(WordMeaningPage);
