import React, { Component } from "react";
import "./index.css";

import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircle from "@material-ui/icons/AddCircleRounded";
import Cross from "@material-ui/icons/CancelRounded";

import pageConstants from "../../consts/pageConstants.json";

export default class SubFilter extends Component {
  constructor() {
    super();
    this.state = {
      selectedSubCategory: null,
      expandSubCategory: true,
    };
  }

  renderChip = (chipType, object, isAllLabel = false) => {
    const selected = chipType === chipTypes.SELECTED;
    const sameVariation =
      this.state.selectedSubCategory?.value === object.value;
    const func = selected
      ? this.onPressChipRemoveButton
      : this.onPressChipSelectButton;
    const labelStyles = isAllLabel
      ? "selectable-chip-text-roboto"
      : selected || sameVariation
      ? "selectable-chip-text-selected"
      : "selectable-chip-text";
    const color =
      (selected || sameVariation) && !isAllLabel ? "primary" : "default";
    const clickable = `selectable-chip ${
      selected || sameVariation ? "" : "not-selected"
    }${isAllLabel ? "all-label" : ""}`;

    const icon = selected ? (
      <Cross style={{ fill: isAllLabel ? "#a93e3c50" : null }} />
    ) : (
      <AddCircle style={{ fill: sameVariation ? null : "#a93e3c50" }} />
    );
    return (
      <Chip
        key={object.value}
        label={object.value}
        clickable={true}
        onDelete={() => func(object)}
        onClick={() => func(object)}
        color={color}
        disabled={isAllLabel || (!selected && sameVariation)}
        classes={{
          clickable: clickable,
          label: labelStyles,
        }}
        deleteIcon={icon}
      />
    );
  };

  renderSelectedChip = () => {
    const AllObject = {
      value: "සියල්ල",
    };
    return this.state.selectedSubCategory === null
      ? this.renderChip(chipTypes.SELECTED, AllObject, true)
      : this.renderChip(chipTypes.SELECTED, this.state.selectedSubCategory);
  };

  onPressChipRemoveButton = () => {
    window.scrollTo(0, 0);
    this.setState({ selectedSubCategory: null, expandSubCategory: true });
    this.props.onSetNewSubCategory(null);
  };

  onPressChipSelectButton = (obj) => {
    window.scrollTo(0, 0);
    this.setState({ selectedSubCategory: obj });
    this.onClickIconButton();
    this.props.onSetNewSubCategory(obj);
  };

  onClickIconButton = () => {
    this.setState({ expandSubCategory: !this.state.expandSubCategory });
  };

  render() {
    const { sub_categories } = this.props.selectedMainCategory;
    return (
      <Accordion
        expanded={this.state.expandSubCategory}
        defaultExpanded={true}
        classes={{ root: "accordian-root" }}
      >
        <AccordionSummary
          classes={{ root: "accordian-summary" }}
          expandIcon={
            <IconButton
              onClick={this.onClickIconButton}
              disableRipple={true}
              disableFocusRipple
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <Typography className="accordian-title">
            {pageConstants.subFilterTitle}
          </Typography>
          {this.renderSelectedChip()}
        </AccordionSummary>
        <AccordionDetails classes={{ root: "accordian-details" }}>
          {Array(sub_categories.length)
            .fill()
            .map((_, i) => i)
            .map((i) => {
              return this.renderChip(chipTypes.NONSELECTED, sub_categories[i]);
            })}
        </AccordionDetails>
      </Accordion>
    );
  }
}

const chipTypes = {
  SELECTED: "selected",
  NONSELECTED: "non-selected",
};
