import React, { Component } from "react";

import pageConstants from "../../consts/pageConstants.json";
import { removeDomain } from "../../utils";
import "./index.css";

export default class TopNavBar extends Component {
  onClickNavItem = (item) => {
    const currentURLPath = removeDomain(window.location.href);
    const interestedPath = `${this.props.currentPath}${item.href}`;

    if (currentURLPath !== interestedPath) {
      switch (item.value) {
        case NavItems.Home.value:
          this.props.history.push(`${this.props.currentPath}/`);
          break;
        case NavItems.Editorial.value:
          this.props.history.push(`${this.props.currentPath}/editorial`);
          break;
        case NavItems.Preface.value:
          this.props.history.push(`${this.props.currentPath}/preface`);
          break;
        case NavItems.Disclaimer.value:
          this.props.history.push(`${this.props.currentPath}/disclaimer`);
          break;
        default:
          break;
      }
    }
  };

  getAvailableNavItems = () => {
    const available = [];
    const navItemsArray = Object.values(NavItems);
    for (let i = 0; i < navItemsArray.length; i++) {
      const currentURLPath = removeDomain(window.location.href);
      const interestedPath = `${this.props.currentPath}${navItemsArray[i].href}`;
      if (navItemsArray[i].id === "home") {
        const interestedSecondPath = `${this.props.currentPath}${navItemsArray[i].href2}`;
        if (
          currentURLPath !== interestedPath &&
          currentURLPath !== interestedSecondPath
        )
          available.push(navItemsArray[i]);
      } else {
        if (currentURLPath !== interestedPath) available.push(navItemsArray[i]);
      }
    }
    return available;
  };

  render() {
    const availableNavItems = this.getAvailableNavItems();
    return (
      <div className="top-nav-bar">
        <div className="top-nav-items">
          {Array(availableNavItems.length)
            .fill()
            .map((_, i) => i)
            .map((i) => (
              <span
                key={availableNavItems[i].id}
                onClick={() => this.onClickNavItem(availableNavItems[i])}
                className="top-nav-text"
              >
                {pageConstants.top_nav[availableNavItems[i].id]}
              </span>
            ))}
        </div>
      </div>
    );
  }
}

const NavItems = {
  Home: {
    id: "home",
    value: "HOME",
    href: "/",
    href2: "",
  },
  Editorial: {
    id: "editorial",
    value: "EDITORIAL",
    href: "/editorial",
  },
  Preface: {
    id: "preface",
    value: "PREFACE",
    href: "/preface",
  },
  Disclaimer: {
    id: "disclaimer",
    value: "DISCLAIMER",
    href: "/disclaimer",
  },
};
