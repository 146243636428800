import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import OpenInNew from "@material-ui/icons/OpenInNew";

import "./index.css";
import pageConstants from "../../consts/pageConstants.json";

export default class CardClass extends Component {
  onClickMeaning = () => {
    this.props.history.push(
      `${this.props.currentPath}/meaning/${this.props.category_id}/${this.props.sub_category_id}/${this.props.item_id}`
    );
  };

  render() {
    return (
      <Card classes={{ root: "single-card" }}>
        <span className="card-title">{this.props.title}</span>
        <span className="card-subtitle">{this.props.subTitle}</span>
        <div className="sample">
          <Button
            onClick={this.onClickMeaning}
            classes={{ root: "card-button" }}
            color="primary"
            startIcon={<OpenInNew />}
            variant="outlined"
          >
            {pageConstants.open_link}
          </Button>
        </div>
      </Card>
    );
  }
}
