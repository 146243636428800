import React, { Component } from "react";
import "./index.css";
import pageConsts from "../../consts/pageConstants.json";
import uniLogo from "../../assets/Images/usj-logo.png";
import cdepdLogo from "../../assets/Images/cdepd-logo.png";

export default class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <div className="footer-container">
        <div className="footer-item-container">
          <div className="img-container">
            <img
              onClick={() => window.open(pageConsts.footer.fhss_link)}
              className="uni-logo"
              alt="University Logo"
              src={uniLogo}
            />
            <img
              onClick={() => window.open(pageConsts.footer.cdepd_link)}
              className="cdepd-logo"
              alt="CDEPD Logo"
              src={cdepdLogo}
            />
          </div>
          <span className="copyright-text">{`${pageConsts.footer.copyright.replace(
            "{year}",
            year
          )} ${pageConsts.footer.all_rights}`}</span>
          <span className="poweredby-text">
            {pageConsts.footer.powered}
            <a className="link-color" href={pageConsts.footer.cdepd_link}>
              {pageConsts.footer.cdepd}
            </a>
          </span>
          <span className="version-text">v.1.2.7</span>
        </div>
      </div>
    );
  }
}
