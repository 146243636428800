import React, { Component } from "react";
import Card from "../../component/card";
import "./index.css";
import dictionary from "../../consts/MainDictionary.json";
import pageConsts from "../../consts/pageConstants.json";
import { isObjEmpty } from "../../utils";
import EmptyImage from "../../assets/Images/empty.png";
export default class BodyContainer extends Component {
  constructor() {
    super();
    this.state = {
      allWords: [],
    };
    this.list = [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchText !== this.props.searchText) {
      this.list = this.state.allWords.filter((obj) => {
        return obj.english_meaning
          .toLowerCase()
          .replace(/-/g, " ")
          .includes(this.props.searchText.toLowerCase());
      });
      this.forceUpdate();
    }
    if (
      prevProps.isSearchEnable !== this.props.isSearchEnable &&
      this.props.isSearchEnable
    ) {
      this.list = this.getWords({}, {});
      this.forceUpdate();
    }
    if (
      prevProps.isSearchEnable !== this.props.isSearchEnable &&
      !this.props.isSearchEnable
    ) {
      this.list = [];
    }
  }

  getWords = (cat, subCat) => {
    if (isObjEmpty(cat)) return this.getAllWords();
    else if (!isObjEmpty(cat) && isObjEmpty(subCat))
      return this.getAllCategotyWords(cat);
    else if (!isObjEmpty(cat) && !isObjEmpty(subCat))
      return this.getSubCategotyWords(cat, subCat.id);
  };

  getSubCategotyWords = (cat, subID) => {
    const words = [];
    for (let i = 0; i < cat.words.length; i++) {
      if (cat.words[i].sub_category_id === subID) words.push(cat.words[i]);
    }
    return words;
  };

  getAllCategotyWords = (cat) => {
    const words = [];
    for (let i = 0; i < cat.words.length; i++) {
      words.push(cat.words[i]);
    }
    return words;
  };

  getAllWords = () => {
    if (this.state.allWords.length === 0) {
      const words = [];
      for (let i = 0; i < dictionary.length; i++) {
        words.push(...dictionary[i].words);
      }
      this.setState({ allWords: words });
      return words;
    }
    return this.state.allWords;
  };

  render() {
    const { category, subCategory, isSearchEnable } = this.props;
    const list = isSearchEnable
      ? this.list
      : this.getWords(category, subCategory);
    return (
      <div className="body-cont">
        {list.length > 0 ? (
          Array(list.length)
            .fill()
            .map((_, i) => i)
            .map((i) => (
              <Card
                key={list[i].id + list[i].sinhala_word}
                title={list[i].sinhala_word}
                subTitle={list[i].english_meaning}
                category_id={list[i].category_id}
                sub_category_id={list[i].sub_category_id}
                item_id={list[i].id}
                history={this.props.history}
                currentPath={this.props.currentPath}
              />
            ))
        ) : (
          <div className="empty_div">
            <h3 className="empty_text">
              {isSearchEnable ? pageConsts.empty_search : pageConsts.empty}
            </h3>
            <img
              className="empty-image"
              src={EmptyImage}
              alt="Empty Illustration"
            />
          </div>
        )}
      </div>
    );
  }
}
